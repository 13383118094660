.header-wrapper {
  display: flex;
  gap: 20px;
  background-color: rgb(179, 170, 218);
  padding-block: 10px;
}
.header-wrapper a {
  text-decoration: none;
  color: rgb(36, 34, 37);
  font-weight: 700;
}
.header-wrapper a:hover {
  color: white;
}
