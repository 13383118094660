.painter-wrapper {
  border: 5px solid rgba(168, 42, 42, 0.733);
  display: flex;
  justify-content: space-between;
  box-shadow: 9px 6px 9px 2px rgba(77, 11, 11, 0.4);
  /* max-height: 80vh; */
}

.painter-image--wrapper {
  padding: 0 20px;
  margin: 0;
  border-left: 12px rgba(168, 42, 42, 0.733) solid;
  background-color: rgba(200, 255, 0, 0.151);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.painter-image {
  height: 65vh;
  user-select: none;
}

.painter-canvas--wrapper {
  cursor: crosshair;
  width: 60%;
}

.color-selection--wrapper {
  display: flex;
  padding: 2rem 2rem 2rem 2rem;
  margin-top: 2rem;
  background-color: rgba(133, 0, 0, 0.473);
  justify-content: space-between;
  align-items: center;
  color: white;
  font-size: 20px;
  font-weight: 600;
}

.color-selection--square {
  width: 30px;
  height: 30px;
}

.color-selection--wrapper button {
  border: 2px white solid;
  border-radius: 100%;
}

.color-selection--wrapper button:hover {
  cursor: pointer;
}

.color-selection--pre {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.color-selection--make {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.slider {
  width: 20rem;
}
